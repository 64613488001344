//#region React Imports
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { withRouter } from 'react-router-dom';
//#endregion

//#region Third Party Imports
import { 
  FaHeart, 
  FaRegHeart, 
  FaCaretDown, 
  FaRegClock, 
  FaRegBell } from 'react-icons/fa';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import InputRange from 'react-input-range';
import 'react-circular-progressbar/dist/styles.css';
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
//#endregionF

//service
import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';
import AppChargerCardService from '../../../ChargerCard/AppChargerCardService';

//#region Constant Imports
import { connectorConstants } from '../../../../driver/helpers';
//#endregion

//#region Components Imports
import AppChargingProgress from '../../AppChargingProgress/AppChargingProgress';
import AppChargingFavorites from '../../AppChargingFavorites/AppChargingFavorites';
import AppChargingScheduleModal from '../../AppChargingModals/AppChargingScheduleModal';
import AppChargingPortModal from '../../AppChargingModals/AppChargingPort';
import AppChargingConnection from '../../AppChargingModals/AppChargingConnection';
import AppChargingService from '../../AppChargingService';
import AppLoadingSpinner from '../../../../shared/Loading/LoadingSpinner';
import AppChargingStatus from '../../AppChargingStatus/AppChargingStatus';
import HistoryContainer from './AppChargingHistory/HistoryContainer';

import { usePrevious } from '../../../../shared/hooks/usePrevious';
//#endregion

//#region Custom Context Imports
import { VehiclesContext } from '../../../../context/driver/getVehiclesContext';
// import DriverLinkedChargers, {
//   DriverChargerContext,
// } from '../../../../context/driver/getDriverLinkedChargers';
import { DriverChargerContext } from '../../../../context/driver/getDriverLinkedChargers';
import { DriverContext } from '../../../../context/driver/getDriverContext';
import { CouponContext } from '../../../../context/driver/addCoupon';
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//#endregion

import { milesToKm, timeConvert } from '../../../../shared/Helpers';
import {
  TitleStyles,
  TextStyles,
  DivStyles,
  ButtonStyles,
} from '../../../../SharedStyles';
import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';
import ChargingBlocked from '../../ChargingBlocked/ChargingBlocked';
// import { ErrorResponse } from '../../../../shared/ErrorResponse';

// import ChargingAutoPayment from '../../AppChargingModals/ChargingAutoPayment';

import WaitModal from '../../../FindCharger/Favorites/modals/WaitModal';
import DriverMessageModal from '../../../FindCharger/Favorites/modals/DriverMessageModal';

const AppChargingContent = (props) => {
  //incoming string values
  const { strings } = AppChargingService;
  //context
  const { coupon, setCoupon } = useContext(CouponContext);
  const { setError } = useContext(ErrorContext);
  const { vehicles, fetchVehicles, defaultVehicle } = useContext(
    VehiclesContext
  );
  const { linkedCharger, setLinkedCharger, linkedChargers } = useContext(
    DriverChargerContext
  );
  const { driver } = useContext(DriverContext);
  //incoming props
  const { index } = props;
  const {
    title,
    chargeLevel,
    chargeLevelMax,
    batteryCapacity,
    allElectricRange,
  } = props.car;

  //current state
  //Charging-Station and Linked Chargers
  const [loading, setLoading] = useState(false);
  const [charger, setCharger] = useState(props.charger); //current charging station via props - can change based on url id and redirects

  //linkedCharger by checking if the driver has a record tied to this charging station
  //Driver Vehicle
  const [vehicle, setVehicle] = useState({}); //set by the useEffect filtered via props

  const [isCharging, setIsCharging] = useState(false); //the session has started or stopped
  const [isFinishing, setIsFinishing] = useState(false); // Indicative of whether or not the driver's session is in a finishing state
  const [showChargingSummary, setShowChargingSummary] = useState(false);

  const [pollTime] = useState(10000);

  const [position, setPosition] = useState(null);

  //Displaying % vs kwh vs miles added
  const [isConvert, setIsConvert] = useState({
    count: 0,
    isPercentage: false,
    isKwh: false,
    kwhDisplayEnd: batteryCapacity,
    kwhDisplayStart: chargeLevel,
    default: true,
  });
  //values for the progress and input slider - starting values incoming from props.car - could check existing car object now. ******JUSTIN***
  const [sliderValues, setSliderValues] = useState({
    min: chargeLevel !== 0 ? chargeLevel : 1,
    // max: parseInt(allElectricRange)
    max: chargeLevelMax !== 0 ? chargeLevelMax : parseInt(allElectricRange),
  });

  const [chargingSessionEta, setChargingSessionEta] = useState({});
  const [etaIntervalId] = useState(0);
  const [pollETA, setPollETA] = useState(false);

  //Message modal updates
  const [errorMessage, setErrorMessage] = useState(''); //all errors
  const [chargingMessage, setChargingMessage] = useState(''); //charging Session updates
  //Modals
  const [schedule, setSchedule] = useState(false); //opens Sx modal
  const [displayConnection, setDisplayConnection] = useState(false); //opens "Message" modal
  const [favoritesOpen, setFavoritesOpen] = useState(false); //opens favorite box
  const [modalPortOpen, setModalPortOpen] = useState(false);

  //Charging Station Status
  const [chargerStatus, setChargerStatus] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [connectors, setConnectors] = useState({
    connectorStatuses: [],
    currentUserConnector: null,
  });
  const [selectedConnector, setSelectedConnector] = useState({});

  // Used to track whether we are in a transition period between Charging
  // and Stopping or Not Charging and Starting
  const [pendingStopCharging, setPendingStopCharging] = useState(false);
  const [pendingStartCharging, setPendingStartCharging] = useState(false);

  const [displayDriverMessageModal, setDisplayDriverMessageModal] = useState(false);


  // const [transactionId, setTransactionId] = useState(null);

  // We must prevent the useEffect for button state management from re-enabling the button because
  // it sees that the connector is "Available" before we've actually received a response from the
  // request the start the charging session.
  const [
    pendingStartChargingResponse,
    setPendingStartChargingResponse,
  ] = useState(false);

  const [scheduleErrors, setScheduleErrors] = useState([]);

  const [chargingData, setChargingData] = useState({
    iotHubDeviceId: props.charger.iotHubDeviceId,
    desiredMaxRange: sliderValues.max,
    startRangeOfVehicle: sliderValues.min,
    maxRangeOfVehicle: parseInt(allElectricRange),
    vehicleBatteryCapacity: batteryCapacity,
  });

  const [waitQueue, setWaitQueue] = useState(false);
  const [inWaitQueue, setInWaitQueue] = useState(false);
  const [userHasActiveQueue, setUserHasActiveQueue] = useState(false);
  
  let inWaitQueueCookie = JSON.parse(localStorage.getItem('inWaitQueue'));

  const toggleDriverMessageModal = () => {
    setDisplayDriverMessageModal(!displayDriverMessageModal);
  };

  // const [allowAutoPayment, setAllowAutoPayment] = useState(true);

  //balance low modal
  // const [lowBalanceModal, setLowBalanceModal] = useState(false);

  // const toggleBalanceModal = () => {
  //   setLowBalanceModal(!lowBalanceModal);
  // };

  // const handleAllowAutoPayment = () => {
  //   setAllowAutoPayment(true);
  // };

  //write modal to prompt allow payments below $5
  //place res in state and send to chad in start

  /**
   * Provides an up-to-date version of chargingData
   * to the setInterval closure for updating ETA
   * information.
   */
  const chargingDataRef = useRef(chargingData);
  chargingDataRef.current = chargingData;

  const favoriteListRef = useRef();
  useOnClickOutside(favoriteListRef, () => setFavoritesOpen(false));

  useEffect(() => {
    setChargingData({
      ...chargingData,
      iotHubDeviceId: props.charger.iotHubDeviceId,
      desiredMaxRange: sliderValues.max,
      startRangeOfVehicle: sliderValues.min,
      maxRangeOfVehicle: parseInt(props.car.allElectricRange),
      vehicleBatteryCapacity: props.car.batteryCapacity,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValues, props.car, props.charger]);

  useEffect(() => {
    setCharger(props.charger);
  }, [props.charger]);
  
  /**
   * Wire up a setInterval that will keep the status of the
   * charging station up-to-date.
   */
  useEffect(() => {
    // Call this initially to update the component right away
    // and then set an interval with which it should be
    // called subsequently.

    setLinkedCharger(props.linkedcharger || null);

    updateChargerStatus(props.charger.iotHubDeviceId);
    startChargerStatusTimer(props.charger.iotHubDeviceId);
    return () => {
      clearInterval(chargerStatusTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.charger]);

  useEffect(() => {
    const checkSvgStatus = async () => {
         //checking wait queue for expired
         if (inWaitQueueCookie) {
          let pos;
          if (inWaitQueueCookie.iotHubDeviceId === charger.iotHubDeviceId) {
            setInWaitQueue(true);
            pos = await AppChargerCardService.waitQueueReady(
              inWaitQueueCookie.iotHubDeviceId
            );
          }
  
          //removing it has expired.
          if (pos && pos.positionInQueue === -1) {
            setError({
              display: true,
              title: 'Wait Queue Expired',
              message: 'Your wait queue grace period for charger ID ' + charger.iotHubDeviceId 
                + ' has expired. You may re-join the wait queue at any time if the charging station is occupied.',
              styles: 'driver',
            });
            localStorage.removeItem('inWaitQueue');
            setInWaitQueue(false);
          }

          setPosition(pos);
        }
      };
      if (Object.keys(charger).length > 0) {
        checkSvgStatus();
      }
  }, [charger, inWaitQueueCookie]);

  // Declare the timer, which we can stop and start as needed
  let chargerStatusTimer;

  const chargingStateStatuses = [
    connectorConstants.Charging,
    connectorConstants.SuspendedEv,
    connectorConstants.SuspendedEvse,
    connectorConstants.Finishing,
  ];

  //opening the favorite chargers to view
  const toggleFavs = () => {
    setFavoritesOpen(!favoritesOpen);
  };

  //opens the Sx modal
  const handleSchedule = () => {
    setScheduleErrors([]);
    setSchedule(!schedule);
    setLoading(false);
  };

  const getScheduleErrors = (scheduleData) => {
    let errors = [];

    if (!scheduleData.connectorId) {
      errors.push(strings.scheduleConnectorError);
    }

    if (!scheduleData.startDateTime || !scheduleData.endDateTime) {
      errors.push(strings.scheduleTimeError);
    }

    return errors;
  };

  const chargingScheduleSubmitHandler = async (scheduleData) => {
    try {
      setLoading(true);

      let errors = getScheduleErrors(scheduleData);
      if (errors.length > 0) {
        setScheduleErrors(errors);
        return;
      }

      const data = {
        iotHubDeviceId: charger.iotHubDeviceId,
        vehicleId: vehicle.id,
        chargeRangeInfo: {
          desiredMaxRange: chargingData.desiredMaxRange,
          startRangeOfVehicle: chargingData.startRangeOfVehicle,
          maxRangeOfVehicle: chargingData.maxRangeOfVehicle,
          vehicleBatteryCapacity: chargingData.vehicleBatteryCapacity,
        },
        connectorId: scheduleData.connectorId,
        enabled: scheduleData.enabled,
        scheduleDaily: scheduleData.scheduleDaily,
        startDateTime: moment(scheduleData.startDateTime).utc().format(),
        endDateTime: moment(scheduleData.endDateTime).utc().format(),
      };
      try {
        await AppChargingService.updateChargingSchedule(data);
        handleSchedule();
      } catch (err) {
        setScheduleErrors([err.response.data]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError({
        display: true,
        title: strings.scheduleErrors,
        message: strings.scheduleErrors,
        styles: 'driver',
      });
    }
  };

  const handleSelectPort = () => {
    setModalPortOpen(!modalPortOpen);
  };

  //Opens the messageModal for errors and connections **CLOSING**
  const handleConnection = () => {
    setDisplayConnection(false);
  };

  /**
   * Keeps the ETA information up-to-date.
   * Called periodically by setInterval during a charging session.
   */
  const updateSessionEta = async () => {
    try {
      //get check the active charging vehicle is there before calling ETA api
      let chargerPort = chargerStatus.connectorStatuses.filter(
        (status) => status.vehicleId === vehicle.id
      );
      if (chargerPort.length === 1) {
        AppChargingService.getChargingSessionEta(chargingDataRef.current).then(
          (data) => {
            setChargingSessionEta(data);
          }
        );
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'driver',
      });
    }
  };

  //effect for getting data on load via props or vehicles calls
  useEffect(() => {
    //pulling out the current car from the vehicles context based on props of the car id
    let currentCar = vehicles.filter((car) => {
      return car.id === props.car.id;
    });

    //setting it to the current Vehicle
    setVehicle(currentCar[0]);
  }, [vehicles, linkedCharger, props.car]);

  //get eta for only active charging vehicles
  useEffect(() => {
    if (Object.keys(chargerStatus).length > 0) {
      updateSessionEta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  //setting the values min and max to the vehicles chargelevel and battery
  useEffect(() => {
    setSliderValues({
      min: chargeLevel !== 0 ? chargeLevel : 1,
      max: chargeLevelMax !== 0 ? chargeLevelMax : parseInt(allElectricRange),
    });
  }, [chargeLevel, chargeLevelMax, allElectricRange]);

  /**
   * Toggle ETA polling on and off depending on the
   * existence of a charging session for the current
   * user.
   */
  useEffect(() => {
    let callIdleFees =
      chargingSessionEta.isChargingComplete && charger.idleRateType === 'none';
    if (
      (isCharging || isFinishing) &&
      connectors.currentUserConnector &&
      connectors.currentUserConnector.isCurrentUser
    ) {
      if (callIdleFees) {
        setPollETA(false);
        return;
      }
      // Call this initially to update the component right away
      // and then set an interval with which it should be
      // called subsequently.
      updateSessionEta();
      setPollETA(true);
    } else {
      setPollETA(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCharging,
    isFinishing,
    charger.idleRateType,
    chargingSessionEta.isChargingComplete,
  ]);

  useEffect(() => {
    let pollingTimer;
    if (pollETA) {
      pollingTimer = setInterval(updateSessionEta, pollTime);
    } else {
      clearInterval(pollingTimer);
    }
    return () => {
      clearInterval(pollingTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollETA, pollTime]);

  const prevStatus = usePrevious(connectors.currentUserConnector);
  const prevCharger = usePrevious(props.charger);

  /**
   * Manages the state of the charging button
   * given the current connector status.
   */
  useEffect(() => {
    setIsCharging(false);
    setIsFinishing(false);

    if (
      Object.keys(chargerStatus).length === 0 &&
      chargerStatus.constructor === Object
    ) {
      return;
    }

    let updatedConnectors = {
      availabilityStatus: chargerStatus.availabilityStatus,
      connectorStatuses: [],
      currentUserConnector: null,
    };

    for (let connector of chargerStatus.connectorStatuses) {
      if (connector.isCurrentUser) {
        updatedConnectors.currentUserConnector = connector;
      } else if (
        (connector.status === connectorConstants.Available ||
          connector.status === connectorConstants.Preparing) &&
        !connector.isCurrentUser
      ) {
        updatedConnectors.connectorStatuses.push(connector);
      }
    }

    setConnectors(updatedConnectors);

    let nonAuthedConnectors = updatedConnectors.connectorStatuses.filter(
      (c) => {
        // At least one connector exists that doesn't have someone else authed. If it's
        // not in an available state and it's in this array, we know that it's preparing
        // but not authenticated.

        return (
          c.status === connectorConstants.Available ||
          c.isCurrentUser === true ||
          !c.isAuthedForOtherUser
        );
      }
    );

    // If there are no available connectors disable the button
    if (nonAuthedConnectors.length === 0) {
      setIsButtonDisabled(true);
    }

    if (prevCharger.iotHubDeviceId !== charger.iotHubDeviceId) {
      setPendingStartCharging(false);
      setPendingStartChargingResponse(false);
    }

    if (updatedConnectors.currentUserConnector) {
      if (
        !pendingStopCharging &&
        chargingStateStatuses.indexOf(
          updatedConnectors.currentUserConnector.status
        ) !== -1
      ) {
        // If the connector is no longer assigned a transactionId, we know that the EV
        // entered a Suspended state for more than 30s and was issued a remote stop.
        // We should maintain the charging data on the screen but disable the button.
        let shouldDisableButton =
          updatedConnectors.currentUserConnector.transactionId < 1 ||
          updatedConnectors.currentUserConnector.status ===
            connectorConstants.Finishing;

        setIsCharging(true);
        setIsButtonDisabled(shouldDisableButton);
        // setTransactionId(updatedConnectors.currentUserConnector.transactionId);

        if (pendingStartCharging) {
          // We previously authenticated, auth was accepted, and now we're charging. Clear pendingStartCharging state
          setPendingStartCharging(false);
        }
      } else if (
        updatedConnectors.currentUserConnector.status ===
        connectorConstants.Finishing
      ) {
        // We've transitioned to a Finishing state and so clear charging state, clear pendingStopCharging state, and disable button.
        setShowChargingSummary(true); // This state will be retained until the page is refreshed OR a new charging session is started
        setIsFinishing(true);
        setIsCharging(false);
        setPendingStopCharging(false);
        setIsButtonDisabled(true);
      }
    } else if (
      !pendingStartCharging &&
      nonAuthedConnectors.length > 0 &&
      isButtonDisabled &&
      updatedConnectors.connectorStatuses.length > 0
    ) {
      // Not pendingStartCharging, the button is disabled (by default) and there are connectors available.
      setIsButtonDisabled(false);
      //enable button otherwise
    } else if (
      pendingStartCharging &&
      !pendingStartChargingResponse &&
      selectedConnector &&
      selectedConnector.status === connectorConstants.Available
    ) {
      // We HAVE sent an auth request and ARE waiting for a charging session to start and we HAVE received a response
      // from the server indicating that it has marked the connector with our idToken and the connector that we chose
      // HAS timed out and went back to available status.
      setPendingStartCharging(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chargerStatus,
    connectors.currentUserConnector,
    connectors.connectorStatuses.length,
    props.charger,
  ]);

  //checking blocked due to member only, wait queue, or reservation
  useEffect(() => {
    let blocked = connectors.connectorStatuses.some((c) => {
      return (
        c.membershipOnlyBlocked || c.waitQueueBlocked || c.reservationBlocked
      );
    });
    if (blocked) {
      setIsButtonDisabled(true);
    }
  }, [connectors]);

  useEffect(() => {
    if (
      prevStatus !== undefined &&
      chargerStatus.connectorStatuses.length > 0
    ) {
      if (
        prevStatus.status === 6 &&
        chargerStatus.connectorStatuses[prevStatus.connectorId - 1].status === 1
      ) {
        setShowChargingSummary(false);
        setChargingSessionEta({});
        clearInterval(etaIntervalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerStatus, prevStatus]);

  const updateChargerStatus = async (chargerId) => {
    try {
      if (Object.keys(charger).length > 0) {
        let updatedStatus = await AppChargingService.getChargingStationStatus(
          chargerId
        );
        setChargerStatus(updatedStatus);

        if (charger.allowWaitQueue) {
          let pos = await AppChargerCardService.waitQueueReady(chargerId);
          setPosition(pos);
        }
      }
    } catch (err) {
      console.error('Failed to call getChargingStationStatus.');
      console.error(err);
    }
  };

  const startChargerStatusTimer = (chargerId) => {
    chargerStatusTimer = setInterval(
      () => updateChargerStatus(chargerId),
      5000
    );
  };

  //watch this function for the empty modal
  const chargePortModalHandler = () => {
    if (
      connectors.connectorStatuses.length > 1 &&
      !connectors.currentUserConnector
    ) {
      handleSelectPort();
    } else if (
      (connectors.connectorStatuses.length === connectorConstants.Available) &
      !connectors.currentUserConnector
    ) {
      handleStartStop(connectors.connectorStatuses[0]);
    } else if (connectors.currentUserConnector) {
      handleStartStop(connectors.currentUserConnector);
    }
  };

  const debounce = (fn, delay) => {
    let timeout;

    return function (...args) {
      const context = this;

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        timeout = null;
        fn.apply(context, args);
      }, delay);
    };
  };

  const syncSliderValues = async (vehicle, values) => {
    vehicle.chargeLevel = values.min < 0 ? 0 : values.min;
    vehicle.chargeLevelMax = values.max;

    await AppChargingService.updateDriverVehicle(vehicle);

    // Reload the context
    fetchVehicles();
  };

  const debounceSyncSliderValues = useCallback(
    debounce(syncSliderValues, 400),
    []
  );

  /**
   * Event handler triggered by clicking the Start/Stop Charging button
   * @param {The selected Connector} port
   */
  const handleStartStop = async (port) => {
    if (Object.keys(selectedConnector).length === 0) {
      setSelectedConnector(port);
    }

    let inWaitQueue = localStorage.getItem('inWaitQueue');

    //update last used timestamp for driver link record
    if (linkedCharger) {
      await AppChargingService.updateChargerLastUsed(charger.iotHubDeviceId);
    }

    setIsButtonDisabled(true);

    props.checkingIsCharging(!isCharging);
    setErrorMessage(false);

    //Charging Session Code - checking if stopped currently
    if (!isCharging) {
      setLoading(true);
      setPendingStartCharging(true);
      setPendingStartChargingResponse(true);
      setShowChargingSummary(false);

      try {
        //if stopped sending call to API to start the chargingSession with Charging station iotHubDevice
        let startingSession = await AppChargingService.startChargingSession({
          iotHubDeviceId: charger.iotHubDeviceId,
          vehicleId: vehicle.id,
          connectorId: port.connectorId,
          couponId:
            coupon && coupon.iotHubDeviceId === charger.iotHubDeviceId
              ? coupon.couponId
              : '',
          csoTestSession: false,
          allowAutoPayment: true, //will change to state if enable funds returns
          chargeRangeInfo: {
            desiredMaxRange: chargingData.desiredMaxRange,
            startRangeOfVehicle: chargingData.startRangeOfVehicle,
            maxRangeOfVehicle: chargingData.maxRangeOfVehicle,
            vehicleBatteryCapacity: chargingData.vehicleBatteryCapacity,
          },
        });

        setCoupon(null);

        if (startingSession.userNeedsToPlugin) {
          // Ensure that we let our button management side effect know that we've received a response
          // from the server and so the user's idToken is attached to the connector on the server side.

          setLoading(false);
          setDisplayConnection(true);
          setChargingMessage({
            header: strings.plugConnector,
            body: strings.plugMessage,
            close: strings.plugClose,
            cancel: strings.plugCancel,
          });
        }
        //if driver can not start charging showing reason why in message modal
        if (!startingSession.isUserEligible) {
          setLoading(false);
          setError({
            display: true,
            title: 'Cannot Start Charging Session',
            message: startingSession.ineligibilityReason,
            styles: 'driver',
          });
          // setDisplayConnection(true);
          // setChargingMessage({
          //   body: startingSession.ineligibilityReason
          // });
          setIsButtonDisabled(false);
        }

        // Eligible to perform charging session AND already plugged in.
        if (
          !startingSession.userNeedsToPlugin &&
          startingSession.isUserEligible
        ) {
          setLoading(false);

          if (
            inWaitQueue &&
            inWaitQueue.iotHubDeviceId === charger.iotHubDeviceId
          ) {
            localStorage.removeItem('inWaitQueue');
          }
          // setIsCharging(!isCharging);
        }
      } catch (e) {
        //error checking
        setLoading(false);
        let error;
        if (e.response) error = JSON.parse(e.response.data);
        if (e.name === 'frontend') {
          setError({
            display: true,
            title: e.title,
            message: e.message,
            styles: 'driver',
          });
          setIsButtonDisabled(false);
        } else if (!error.isUserEligible) {
          let reason = error.ineligibilityReason;
          if (reason.includes('Credit Card')) {
            reason = strings.noCardOnFile;
          }
          setError({
            display: true,
            title: 'Cannot Start Session',
            message: reason,
            styles: 'driver',
          });
          setIsButtonDisabled(false);
        } else {
          setError({
            display: true,
            title: strings.failedStartCharger,
            message: strings.failedStartChargerContent,
            styles: 'driver',
          });
          setIsButtonDisabled(false);
        }
      }
    } else {
      //if charging is active
      setLoading(true);
      setPendingStopCharging(true);

      try {
        //on click this will pass iothubdevice to backend to stop the charging session
        await AppChargingService.stopChargingSession({
          iotHubDeviceId: charger.iotHubDeviceId,
          vehicleId: vehicle.id,
        });
        // setIsCharging(false);
        setLoading(false);
        // more conditions to write waiting on backend
      } catch (e) {
        //error checking
        //displaying message in message modal
        setLoading(false);
        setDisplayConnection(true);
        setChargingMessage({
          header: strings.plugError,
          body: e.response.data,
          close: strings.plugClose,
          cancel: strings.plugCancel,
        });
      }
    }
  };

  //===================will change based on metervalues ================

  useEffect(() => {
    let meterMeasurements = localStorage.getItem('meterMeasurement');
    if (meterMeasurements) {
      setIsConvert(JSON.parse(meterMeasurements));
    }
  }, []);

  //function for %, kwH and miles added
  const handleConverison = () => {
    //currently only Percentage
    if (isConvert.count === 0) {
      setIsConvert({
        ...isConvert,
        isKwh: true,
        default: false,
        count: 1,
        isPercentage: false,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          ...isConvert,
          isKwh: true,
          default: false,
          count: 1,
          isPercentage: false,
        })
      );
    }
    if (isConvert.count === 1) {
      setIsConvert({
        ...isConvert,
        isKwh: false,
        default: false,
        count: 2,
        isPercentage: true,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          ...isConvert,
          isKwh: false,
          default: false,
          count: 2,
          isPercentage: true,
        })
      );
    }
    if (isConvert.count === 2) {
      setIsConvert({
        ...isConvert,
        count: 0,
        isPercentage: false,
        isKwh: false,
        default: true,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          ...isConvert,
          count: 0,
          isPercentage: false,
          isKwh: false,
          default: true,
        })
      );
    }
  };

  //turns kwh to percentage
  const convertToPercentage = (el, starting, stopping) => {
    return ((starting / stopping) * 100).toFixed(0);
  };

  //favorite and unfavorite linked and charging stations
  const toggleFavorites = async (charger) => {
    try {
      //declaring empty object
      let chargerUpdated = {
        iotHubDeviceId: charger.iotHubDeviceId,
        favorite: !charger.favorite,
        home: charger.home,
      };
      //making the API call with the new Charger Object to update or create link
      let updatedCharger = await AppChargingService.linkDriverCharger(
        chargerUpdated
      );
      setLinkedCharger(updatedCharger);

      props.getCharger(driver.driverLocation, charger.iotHubDeviceId);
      setCharger((c) => ({ ...c, favorite: updatedCharger.favorite }));
    } catch (e) {
      if (e.response) {
        //displaying message in message modal
        setDisplayConnection(true);
        setErrorMessage(e.response.data);
      }
    }
  };

  const chargerListFav = () => {
    let sorted = linkedChargers
      .filter((charger) => {
        return charger.favorite || charger.home || charger.assigned;
      })
      .sort((a, b) => {
        if (a.home || b.home) {
          if (a.home === b.home) {
            return (
              new Date(a.lastChargeTimeStamp) - new Date(b.lastChargeTimeStamp)
            );
          } else {
            return b.home - a.home;
          }
        } else if (a.assigned || b.assigned) {
          if (a.assigned === b.assigned) {
            return (
              new Date(a.lastChargeTimeStamp) - new Date(b.lastChargeTimeStamp)
            );
          } else {
            return b.assigned - a.assigned;
          }
        } else {
          return -1;
        }
      });
    return sorted.map((charger) => {
      return (
        <div
          key={charger.id}
          onClick={() => {
            props.history.push(`/charging/${charger.iotHubDeviceId}`);
            toggleFavs();
          }}>
          <AppChargingFavorites
            charger={charger}
            driver={driver}
            defaultVehicle={defaultVehicle}
          />
        </div>
      );
    });
  };

  const startChargingAfterConnection = (canCharge) => {
    setIsCharging(canCharge);
  };

  const getkWDisplay = () => {
    var unitOfMeasurement = chargingSessionEta.isChargingComplete
      ? ' kW (avg)'
      : ' kW';

    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? '-- kW'
      : `${(chargingSessionEta.chargerOutput / 1000).toFixed(
          1
        )} ${unitOfMeasurement}`;
  };

  var getMPHDisplay = () => {
    var unitOfMeasurement =
      driver.unitOfMeasurement === 'Miles'
        ? chargingSessionEta.isChargingComplete
          ? ' mi/h (avg)'
          : 'mi/hr'
        : chargingSessionEta.isChargingComplete
        ? ' kM/h (avg)'
        : 'kM/hr';

    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? driver.unitOfMeasurement === 'Miles'
        ? '-- mi/hr'
        : '-- kM/hr'
      : `${
          driver.unitOfMeasurement === 'Miles'
            ? chargingSessionEta.milesPerHour
            : parseInt(chargingSessionEta.milesPerHour * 1.60934).toFixed(2)
        } ${unitOfMeasurement}`;
  };

  var getStartedDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? '--'
      : moment(chargingSessionEta.startedTimestamp).format('h:mm:ss a');
  };

  var getConnectedDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? '--'
      : moment(chargingSessionEta.vehicleConnectTime).format('h:mm:ss a');
  };

  var getElapsedDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? '--'
      : `${chargingSessionEta.elapsedTime.Hours}h ${chargingSessionEta.elapsedTime.Minutes}m ${chargingSessionEta.elapsedTime.Seconds}s`;
  };

  var getEtaDisplay = () => {
    // Note: When showing the charging summary, the ETA should no longer be dispalyed as the session has ended.

    return Object.keys(chargingSessionEta).length === 0 ||
      showChargingSummary ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId) ||
      (connectors.currentUserConnector &&
        connectors.currentUserConnector.status !== connectorConstants.Charging)
      ? '--'
      : chargingSessionEta.estimatedTimeRemainingMins >= 1440
      ? '24hrs +'
      : moment()
          .add(chargingSessionEta.estimatedTimeRemainingMins, 'minutes')
          .format('h:mm a');
  };

  var getFinishedDisplay = () => {
    return showChargingSummary ||
      (Object.keys(chargingSessionEta).length !== 0 &&
        chargingSessionEta.isChargingComplete &&
        connectors.currentUserConnector &&
        props.car.id === connectors.currentUserConnector.vehicleId)
      ? moment(chargingSessionEta.finishedTimestamp).format('h:mm a')
      : '--';
  };

  var getRateDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? charger.home
        ? driver.homeChargerRate === ''
          ? `$0.00/kWh`
          : `${AppPlatformService.currencyFormat(driver.homeChargerRate)}/kWh`
        : charger.rateType === 'Free'
        ? charger.rateType
        : `${charger.rate}/${charger.rateType}`
      : charger.home
      ? driver.homeChargerRate === ''
        ? `$0.00/kWh`
        : `$${parseFloat(driver.homeChargerRate).toFixed(2)}/kWh`
      : chargingSessionEta.rateType === 'Free'
      ? chargingSessionEta.rateType
      : chargingSessionEta.rateType !== ''
      ? `${AppPlatformService.currencyFormat(chargingSessionEta.rate)}/${chargingSessionEta.rateType}`
      : `${AppPlatformService.currencyFormat(chargingSessionEta.rate)}`;
  };

  var getAmountDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0 ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId &&
        !showChargingSummary)
      ? ' --'
      : `${AppPlatformService.currencyFormat(chargingSessionEta.cost)}`;
  };

  //Progress Bar in the center updates
  const getTotalProgressUpdates = () => {
    if (isConvert.default) {
      if (
        (connectors.currentUserConnector &&
          props.car.id !== connectors.currentUserConnector.vehicleId &&
          !showChargingSummary) ||
        Object.keys(chargingSessionEta).length === 0
      ) {
        return (
          <>
            <div className='header-progress'>{strings.range}</div>
            <div>{milesToKm(driver.unitOfMeasurement, sliderValues.min)}</div>
          </>
        );
      } else {
        return (
          <>
            <div className='header-progress'>{strings.range}</div>
            <div>
              {milesToKm(
                driver.unitOfMeasurement,
                (sliderValues.min + chargingSessionEta.addedRange).toFixed(0)
              )}
            </div>
          </>
        );
      }
    }
    if (isConvert.isKwh) {
      if (
        (connectors.currentUserConnector &&
          props.car.id !== connectors.currentUserConnector.vehicleId &&
          !showChargingSummary) ||
        Object.keys(chargingSessionEta).length === 0
      ) {
        return (
          <>
            <div className='header-progress'>{strings.energy}</div>
            <div>
              {Math.ceil(
                parseInt(sliderValues.min) /
                  (parseInt(allElectricRange) / batteryCapacity)
              )}{' '}
              kWh
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className='header-progress'>{strings.energy}</div>
            <div>
              {(
                Math.ceil(
                  parseInt(sliderValues.min) /
                    (parseInt(allElectricRange) / batteryCapacity)
                ) + chargingSessionEta.kwhDelivered
              ).toFixed(1)}{' '}
              kWh
            </div>
          </>
        );
      }
    }
    if (isConvert.isPercentage) {
      if (
        (connectors.currentUserConnector &&
          props.car.id !== connectors.currentUserConnector.vehicleId &&
          !showChargingSummary) ||
        Object.keys(chargingSessionEta).length === 0
      ) {
        return (
          <>
            <div className='header-progress'>{strings.percentage}</div>
            <div>
              {convertToPercentage(
                null,
                parseInt(sliderValues.min),
                parseInt(allElectricRange)
              )}
              %
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className='header-progress'>{strings.percentage}</div>
            <div>
              {convertToPercentage(
                null,
                parseInt(sliderValues.min + chargingSessionEta.addedRange),
                parseInt(allElectricRange)
              )}
              %
            </div>
          </>
        );
      }
    }
  };

  const checkStatusOfPorts = () => {
    if (
      !isCharging ||
      (connectors.currentUserConnector &&
        props.car.id !== connectors.currentUserConnector.vehicleId)
    ) {
      return strings.startCharging;
    }

    if (
      connectors.currentUserConnector &&
      props.car.id === connectors.currentUserConnector.vehicleId &&
      connectors.currentUserConnector.status === 6
    ) {
      return strings.finished;
    }

    return strings.stopCharging;
  };

  const handleModal = () => {
    if (inWaitQueueCookie && inWaitQueueCookie.inQueue) {
      setUserHasActiveQueue(true);
    }
    setWaitQueue(!waitQueue);
  };

  const checkingWaitQueue = (status) => {
    setInWaitQueue(status);
  };

  const handleUserInActiveQueue = (boo) => {
    setUserHasActiveQueue(boo);
  };

  return (
    <>
      {loading && <AppLoadingSpinner />}
      <div key={title} className='slide'>
        {displayConnection && (
          <AppChargingConnection
            handlePendingChargingResponse={setPendingStartChargingResponse}
            errorMessage={errorMessage}
            chargerStatus={chargerStatus}
            port={selectedConnector.connectorId}
            handleStartStop={handleStartStop}
            chargingMessage={chargingMessage}
            handleModal={handleConnection}
            startChargingAfterConnection={startChargingAfterConnection}
            selectedConnector={selectedConnector}
          />
        )}
        {schedule && (
          <AppChargingScheduleModal
            handleModal={handleSchedule}
            submit={chargingScheduleSubmitHandler}
            connectors={chargerStatus}
            iotHubDeviceId={charger.iotHubDeviceId}
            vehicleId={vehicle.id}
            errors={scheduleErrors}
          />
        )}
        {modalPortOpen && (
          <AppChargingPortModal
            strings={strings}
            onclick={handleStartStop}
            closeModal={chargePortModalHandler}
            connectors={connectors}
          />
        )}
        {/* AUto Payment Feature Modal */}
        {/* {lowBalanceModal &&
          !allowAutoPayment &&
          Object.keys(chargingSessionEta).length === 0 && (
            <ChargingAutoPayment
              strings={strings}
              handleAllowAutoPayment={handleAllowAutoPayment}
              handleModalClose={toggleBalanceModal}
            />
          )} */}
        
        {displayDriverMessageModal && (
          <DriverMessageModal
            lastMessage={chargerStatus.lastMessage}
            closeModal={toggleDriverMessageModal}
            setAsRead={() => setChargerStatus({ ...chargerStatus, lastMessage: null })}

          />
        )}

        {index === 0 ? (
          <div className='charging-content-container'>
            <div className='charging-content-header'>
              <div className='charging-content-title'>
                {charger.chargingStationName || charger.chargerName}{' '}
                {charger.assigned && `- ${strings.assigned}`}
                {charger.home && `- ${strings.owned}`}
              </div>
              <div className='charging-content-selection'>
                {chargerStatus?.lastMessage ? (
                    <FaRegBell onClick={toggleDriverMessageModal} />
                  ) : null}
                {!charger.home && !charger.assigned && charger.allowWaitQueue ? (
                  <div
                    color={
                      inWaitQueueCookie &&
                      inWaitQueueCookie.iotHubDeviceId ===
                        charger.iotHubDeviceId
                        ? 'red'
                        : null
                    }>
                    <FaRegClock onClick={() => handleModal()}/>
                    {position && position.positionInQueue >= 0 && (
                      <span>
                        &nbsp;{position.positionInQueue + 1}
                      </span>
                    )}                  
                  </div>
                ) : null}
                {!charger.home && !charger.assigned ? (
                  charger.favorite ? (
                    <FaHeart onClick={() => toggleFavorites(charger)} />
                  ) : (
                    <FaRegHeart onClick={() => toggleFavorites(charger)} />
                  )
                ) : null}
                {!connectors.currentUserConnector &&
                !isCharging &&
                chargerStatus.availabilityStatus !== 3 ? (
                  <button className='faicon faicon-border'>
                    <FaCaretDown onClick={toggleFavs} id='modal' />
                  </button>
                ) : null}
              </div>
              {favoritesOpen && (
                <div className='chrgrlistmenu' ref={favoriteListRef}>
                  {chargerListFav()}
                </div>
              )}
            </div>

            <div className='charging-data-container'>
              <div className='charging-data watts'>{getkWDisplay()} </div>
              <div className='charging-data miles'>{getMPHDisplay()}</div>
              <div className='charging-data carId'>
                {charger.connectors.map((c, i) => (
                  <span key={c.connectorName}>
                    {c.connectorName}
                    {i !== charger.connectors.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            </div>

            <div className='charging-progress-container'>
              <AppChargingStatus
                vehicle={vehicle}
                eta={chargingSessionEta}
                chargingStatusTitle={strings.chargingStatus}
                connectors={chargerStatus}
                currentUserConnector={connectors.currentUserConnector}
              />

              <div className='Charging-progress-container'>
                <AppChargingProgress
                  value={sliderValues.min}
                  currentChargingCar={
                    connectors.currentUserConnector &&
                    props.car.id === connectors.currentUserConnector.vehicleId
                  }
                  addedRange={
                    Object.keys(chargingSessionEta).length > 0
                      ? chargingSessionEta.addedRange
                      : 0
                  }
                  isCharging={isCharging}
                  batteryCapacity={parseInt(allElectricRange)}
                  stopBattery={sliderValues.max}
                  handleStartStop={handleStartStop}>
                  {(value) => (
                    <>
                      <div
                        className='Progress-section'
                        onClick={handleConverison}>
                        <CircularProgressbarWithChildren
                          maxValue={parseInt(allElectricRange)}
                          value={value.stopValue}
                          circleRatio={0.75}
                          background
                          strokeWidth={9}
                          backgroundPadding={2}
                          styles={buildStyles({
                            backgroundColor: '#2c456c',
                            pathColor: 'grey',
                            trailColor: '#eee',
                            textColor: '#fff',
                            fontSize: '16px',
                            rotation: 1 / 2 + 1 / 8,
                          })}>
                          <CircularProgressbarWithChildren
                            maxValue={parseInt(allElectricRange)}
                            value={value.valuesIndex}
                            circleRatio={0.75}
                            background
                            backgroundPadding={2.5}
                            styles={buildStyles({
                              trailColor: 'transparent',
                              backgroundColor: 'transparent',
                              pathColor: 'lime',
                              textColor: '#fff',
                              fontSize: '16px',
                              rotation: 1 / 2 + 1 / 8,
                            })}>
                            <div id='progressChild'>
                              {getTotalProgressUpdates()}
                            </div>
                          </CircularProgressbarWithChildren>
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className='charging-progress-startingBattery'>
                        <InputRange
                          disabled={
                            connectors.currentUserConnector &&
                            props.car.id ===
                              connectors.currentUserConnector.vehicleId
                          }
                          value={sliderValues}
                          formatLabel={(values) =>
                            isConvert.default
                              ? milesToKm(driver.unitOfMeasurement, values)
                              : isConvert.isKwh
                              ? `${Math.ceil(
                                  values /
                                    (parseInt(allElectricRange) /
                                      batteryCapacity)
                                )} kWh`
                              : `${convertToPercentage(
                                  'sliders',
                                  values,
                                  parseInt(allElectricRange)
                                )}%`
                          }
                          maxValue={parseInt(allElectricRange)}
                          minValue={0}
                          onChange={(values) => {
                            setSliderValues({
                              min: values.min < 0
                                ? values.min = 0
                                : values.min,
                              max:
                                values.max >= value.valuesIndex
                                  ? values.max
                                  : value.valuesIndex,
                            });
                            debounceSyncSliderValues(vehicle, values);
                          }}
                        />
                        <p
                          className={
                            connectors.currentUserConnector &&
                            props.car.id ===
                              connectors.currentUserConnector.vehicleId
                              ? 'invisible'
                              : ''
                          }>
                          {strings.adjustRange}
                        </p>
                      </div>
                    </>
                  )}
                </AppChargingProgress>
              </div>
            </div>

            <div className='charging-time-container'>
              <div className='charging-time charging-time-header'>
              <div className='charging-time-item'>{strings.connected}</div>
              <div className='charging-time-item'>{strings.started}</div>
              <div className='charging-time-item'>{strings.elapsed}</div>
                <div className='charging-time-item'>{strings.eta}</div>
                <div className='charging-time-item'>{strings.finished}</div>
              </div>
              <div className='charging-time charging-time-content'>
              <div className='charging-time-item'>{getConnectedDisplay()}</div>
              <div className='charging-time-item'>{getStartedDisplay()}</div>
              <div className='charging-time-item'>{getElapsedDisplay()}</div>
                <div className='charging-time-item'>{getEtaDisplay()}</div>
                <div className='charging-time-item'>{getFinishedDisplay()}</div>
              </div>
            </div>
            {/*console.log(charger)*/}
            <div className='charging-buttons-container'>
              <ButtonStyles
                padding='6px 10px'
                disabled={
                  isButtonDisabled ||
                  loading ||
                  isFinishing ||
                  (connectors.currentUserConnector &&
                    props.car.id !== connectors.currentUserConnector.vehicleId)
                }
                onClick={chargePortModalHandler}>
                {checkStatusOfPorts()}
              </ButtonStyles>
              {(charger.home || charger.assigned) && (
                <ButtonStyles
                  inverse
                  padding='6px 10px'
                  onClick={handleSchedule}
                  disabled={loading}>
                  {strings.titleSchedule}
                </ButtonStyles>
              )}
            </div>
            {/* Blocked Reason */}
            <ChargingBlocked connectors={connectors} />
            {Object.keys(chargingSessionEta).length > 0 &&
              chargingSessionEta.stoppedForLackOfFunds && (
                <div className='Auto-Session'>
                  <p>
                    <em className='danger-text'>{strings.stopDueToPayment}</em>
                  </p>
                </div>
              )}
            <div className='charging-pricing-container'>
              <div className='charging-rate'>
                {Object.keys(chargingSessionEta).length === 0 ? (
                  <>
                    {charger.home
                      ? strings.titleUtility
                      : charger.membershipId !== ''
                      ? strings.titleMemberRate
                      : strings.titleRate}
                    : {getRateDisplay()}
                  </>
                ) : (
                  <>
                    {charger.home
                      ? strings.titleUtility
                      : charger.membershipId !== ''
                      ? strings.titleMemberRate
                      : strings.titleRate}
                    : {getRateDisplay()}
                  </>
                )}
              </div>
              <div className='charging-amount'>
                {strings.titleAmount}: {getAmountDisplay()}
              </div>
            </div>
            {charger.minAmount > 0 && (
              <DivStyles
                display='flex'
                flexWrap='no-wrap'
                justifyContent='center'
                borderTop='1px solid #f3f3f3'
                borderBottom='1px solid #f3f3f3'
                margin='5px 0'
                padding='3px 0'>
                <DivStyles
                  flex='45% 1'
                  color='#2c456c'
                  size='14px'
                  borderRight='1px solid #f3f3f3'
                  padding='3px 0'>
                  <TextStyles margin='5px 0' lineHeight='1'>
                    Min Amount: ${charger.minAmount.toFixed(2)}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  flex='45% 1'
                  color='#2c456c'
                  size='14px'
                  borderLeft='1px solid #f3f3f3'
                  padding='3px 0'
                />
              </DivStyles>
            )}            
            {charger.maxTime > 0 && (
              <DivStyles
                display='flex'
                flexWrap='no-wrap'
                justifyContent='center'
                borderTop='1px solid #f3f3f3'
                borderBottom='1px solid #f3f3f3'
                margin='5px 0'
                padding='3px 0'>
                <DivStyles
                  flex='45% 1'
                  color='#2c456c'
                  size='14px'
                  borderRight='1px solid #f3f3f3'
                  padding='3px 0'>
                  <TextStyles margin='5px 0' lineHeight='1'>
                    Max Session Time: {timeConvert(charger.maxTime)}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  flex='45% 1'
                  color='#2c456c'
                  size='14px'
                  borderLeft='1px solid #f3f3f3'
                  padding='3px 0'
                />
              </DivStyles>
            )}
            {charger.enabledSubscription && (
              <>
              <>
                {/* Idle fees section kicks in when charging session starts - per jeff 5/21/2020 */}
                {Object.keys(chargingSessionEta).length > 0 &&
                  connectors.currentUserConnector &&
                  props.car.id === connectors.currentUserConnector.vehicleId &&
                  chargingSessionEta.idleRateType !== '' &&
                  chargingSessionEta.idleRateType !== 'none' &&
                  !charger.home && (
                    <DivStyles
                      display='flex'
                      flexWrap='no-wrap'
                      justifyContent='center'
                      borderTop='1px solid #f3f3f3'
                      borderBottom='1px solid #f3f3f3'
                      margin='5px 0'
                      padding='3px 0'>
                      <DivStyles
                        flex='45% 1'
                        color='#2c456c'
                        size='14px'
                        borderRight='1px solid #f3f3f3'
                        padding='3px 0'>
                        <TitleStyles size='14px' weight='700' margin='3px 0 0'>
                          {strings.idleFeesApplied}
                        </TitleStyles>
                        <TextStyles margin='5px 0' lineHeight='1'>
                          {strings.titleRate}: $
                          {chargingSessionEta.idleRate.toFixed(2)}/
                          {chargingSessionEta.idleRateType === ''
                            ? 'none'
                            : chargingSessionEta.idleRateType}
                        </TextStyles>
                        <TextStyles margin='0' lineHeight='1'>
                          {strings.idleFeesGrace}:{' '}
                          {timeConvert(chargingSessionEta.gracePeriod)}
                        </TextStyles>
                      </DivStyles>
                      <DivStyles
                        flex='45% 1'
                        color='#2c456c'
                        size='14px'
                        borderLeft='1px solid #f3f3f3'
                        padding='3px 0'>
                        <TextStyles margin='0 0 5px'>
                          {strings.idleFeesStart}:{' '}
                          {chargingSessionEta.idleStartTime !==
                          '0001-01-01T00:00:00'
                            ? moment(chargingSessionEta.idleStartTime).format(
                                'hh:mma'
                              )
                            : '--'}
                        </TextStyles>
                        <TitleStyles
                          color={
                            chargingSessionEta.idleCost > 0
                              ? '#ff0004'
                              : '#2c456c'
                          }
                          size='20px'
                          weight='600'>
                          {strings.titleAmt}: $
                          {chargingSessionEta.idleCost <= 0
                            ? '--'
                            : chargingSessionEta.idleCost.toFixed(2)}
                        </TitleStyles>
                      </DivStyles>
                    </DivStyles>
                  )}
              </>

              <>
                {/* Idle fees section kicks in when charging session starts - per jeff 5/21/2020 */}
                {Object.keys(chargingSessionEta).length === 0 &&
                  charger.idleRateType !== '' &&
                  charger.idleRateType !== 'none' &&                  
                  Object.keys(charger).length > 0 &&
                  !charger.home && (
                    <DivStyles
                      display='flex'
                      flexWrap='no-wrap'
                      justifyContent='center'
                      borderTop='1px solid #f3f3f3'
                      borderBottom='1px solid #f3f3f3'
                      margin='5px 0'
                      padding='3px 0'>
                      <DivStyles
                        flex='45% 1'
                        color='#2c456c'
                        size='14px'
                        borderRight='1px solid #f3f3f3'
                        padding='3px 0'>
                        <TitleStyles size='14px' weight='700' margin='3px 0 0'>
                          {strings.idleFeesApplied}
                        </TitleStyles>
                        <TextStyles margin='5px 0' lineHeight='1'>
                          {strings.titleRate}: $
                          {charger.idleRate.toFixed(2)}/
                          {charger.idleRateType === ''
                            ? 'none'
                            : charger.idleRateType}
                        </TextStyles>
                        <TextStyles margin='0' lineHeight='1'>
                          {strings.idleFeesGrace}:{' '}
                          {timeConvert(charger.gracePeriod)}
                        </TextStyles>
                      </DivStyles>
                      <DivStyles
                        flex='45% 1'
                        color='#2c456c'
                        size='14px'
                        borderLeft='1px solid #f3f3f3'
                        padding='3px 0'>
                      </DivStyles>
                    </DivStyles>
                  )}
                </>
              </>
            )}

            {waitQueue && (
              <WaitModal
                handleFavoriteModal={handleModal}
                charger={charger}
                checkingWaitQueue={checkingWaitQueue}
                hasActiveQueue={userHasActiveQueue}
                handleUserInActiveQueue={handleUserInActiveQueue}
              />
            )}
          </div>
        ) : (
          <HistoryContainer
            strings={strings}
            driver={driver}
            vehicle={props.car}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(AppChargingContent);
