import React, { useState, useEffect } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

//services
import AppPricingSchedulesService from '../../AppPricingSchedulesService';

//global components
import RoleCanEdit from '../../../OperatorShared/RoleCanEdit';
import ToggleSwitch from '../../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//hooks
import { useHours } from '../../../../shared/hooks/useHours';

//styles
import {
  DivStyles,
  InputStyles,
  SelectStyles,
  TextStyles,
  LabelStyles,
} from '../../../../SharedStyles';

//helpers
import { minsGraceToReadable, minsToMaxTime } from '../../helpers';
import { SubscriptionAccess } from '../../../OperatorShared';

const Time = (props) => {
  const { strings } = AppPricingSchedulesService;
  const [hours] = useHours();
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');

  //needs to update on change to the array
  const handleChange = (e, time) => {
    if (time === 'timeStart') {
      setTimeStart(e.target.value);
      if (e.target.value > hours[0].time && props.allDay) {
        props.toggleAllDay();
      }
    }
    if (time === 'timeEnd') {
      setTimeEnd(e.target.value);
      if (e.target.value < hours[hours.length - 1].time && props.allDay) {
        props.toggleAllDay();
      }
    }
    if (e.target.name === 'rate') {
      if (e.target.value === '') {
        props.handleTimeChange('rateType', 'free', props.time.id);
      } else {
        props.handleTimeChange(
          [e.target.name],
          parseFloat(e.target.value),
          props.time.id
        );
      }
    }
    if (e.target.name === 'idleRate') {
      if (e.target.value === '') {
        props.handleTimeChange('idleRateType', 'none', props.time.id);
        props.handleTimeChange('idleGracePeriod', '', props.time.id);
      } else {
        props.handleTimeChange(
          [e.target.name],
          parseFloat(e.target.value),
          props.time.id
        );
      }
    }
    if (e.target.name === 'idleRateType') {
      if (e.target.value === 'none') {
        props.handleTimeChange('idleRate', '', props.time.id);
        props.handleTimeChange('idleGracePeriod', '', props.time.id);
      } else {
        props.handleTimeChange(
          [e.target.name],
          parseFloat(e.target.value),
          props.time.id
        );
      }
    }
    if (e.target.name === 'rateType') {
      if (e.target.value === 'free') {
        props.handleTimeChange('rate', 0, props.time.id);
        props.handleTimeChange([e.target.name], e.target.value, props.time.id);
      }
      props.handleTimeChange([e.target.name], e.target.value, props.time.id);
      //set rateContinueUntil to session ends if not time based
      if (!(e.target.value === 'min' || e.target.value === 'hr')) {
        props.handleTimeChange('rateContinueUntil', 0, props.time.id);
      }
    }   
    if (e.target.name === 'supportsLandingPagePayments') {
      props.handleTimeChange([e.target.name], e.target.checked, props.time.id);
      return;
    }
    if (e.target.name === 'rateContinueUntil') {
      props.handleTimeChange([e.target.name], parseInt(e.target.value), props.time.id);
      return;
    }
    props.handleTimeChange([e.target.name], e.target.value, props.time.id);
  };

  useEffect(() => {
    if (props.allDay) {
      setTimeStart(hours[0].time);
      setTimeEnd(hours[hours.length - 1].time);
      props.handleTimeChange('timeStart', hours[0].time, props.time.id);
      props.handleTimeChange(
        'timeEnd',
        hours[hours.length - 1].time,
        props.time.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allDay]);

  useEffect(() => {
    setTimeEnd(props.time.timeEnd);
    setTimeStart(props.time.timeStart);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.time]);

  const getTimeSelected = (e) => {
    setTimeStart(e.target.value);
  };

  const removeTime = () => {
    props.removeTimeFromSx(props.time.id);
  };

  return (
    <>
      <DivStyles>
        {/* Schedule Header */}
        <DivStyles
          display='flex'
          alignItems='center'
          svgColor='#f44'
          svgSize='20px'
          svgMargin='0 0 0 10px'>
          <TextStyles size='14px' margin='0'>
            {strings.time}: {props.count}
          </TextStyles>
          <RoleCanEdit roles={[1]}>
            {props.times.length > 1 && (
              <FaRegTimesCircle onClick={removeTime} />
            )}
          </RoleCanEdit>
        </DivStyles>
        {/* SupportsLandingPagePayments for New and Edit */}
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 0 0 10px' minWidth='75px'>
            {strings.supportsLandingPagePayments}
          </LabelStyles>
          <ToggleSwitch
            margin='0 10px 0 3px'
            name='supportsLandingPagePayments'
            handleChange={handleChange}
            // disabled={props.adminPermission}
            checked={props.time.supportsLandingPagePayments}
          />
        </DivStyles>
        <SubscriptionAccess levels={[3]}>
          {/* Time Select for New and Edit */}
          <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
            {props.editForm ? (
              <>
                <LabelStyles margin='0 10px' minWidth='75px'>
                  {strings.time}
                </LabelStyles>
                <SelectStyles
                  width='95px'
                  margin='0 10px 0 0'
                  name='timeStart'
                  value={timeStart}
                  onChange={(e) => {
                    handleChange(e, 'timeStart');
                    getTimeSelected(e);
                  }}>
                  <option>{strings.selectTime}</option>
                  {hours.map((hour) => {
                    return (
                      <option key={hour.display} value={hour.time}>
                        {hour.display}
                      </option>
                    );
                  })}
                </SelectStyles>{' '}
                -{' '}
                <SelectStyles
                  width='95px'
                  margin='0 10px 0 0'
                  name='timeEnd'
                  value={timeEnd}
                  onChange={(e) => {
                    handleChange(e, 'timeEnd');
                  }}>
                  <option>{strings.selectTime}</option>
                  {hours.map((hour) => {
                    if (hour.time > timeStart) {
                      return (
                        <option key={hour.display} value={hour.time}>
                          {hour.display}
                        </option>
                      );
                    }
                    return null;
                  })}
                </SelectStyles>
              </>
            ) : (
              <>
                <LabelStyles margin='0 10px' minWidth='75px' color='#c2c2c2'>
                  {strings.time}
                </LabelStyles>
                <SelectStyles
                  margin='0 10px 0 0'
                  width='95px'
                  value={timeStart}
                  name='timeStart'
                  onChange={(e) => {
                    handleChange(e, 'timeStart');
                    getTimeSelected(e);
                  }}>
                  <option>{strings.selectTime}</option>
                  {hours.map((hour) => {
                    return (
                      <option key={hour.display} value={hour.time}>
                        {hour.display}
                      </option>
                    );
                  })}
                </SelectStyles>{' '}
                -{' '}
                <SelectStyles
                  width='95px'
                  margin='0 10px 0 0'
                  name='timeEnd'
                  value={timeEnd}
                  onChange={(e) => {
                    handleChange(e, 'timeEnd');
                  }}>
                  <option>{strings.selectTime}</option>
                  {hours.map((hour) => {
                    if (hour.time > timeStart) {
                      return (
                        <option key={hour.display} value={hour.time}>
                          {hour.display}
                        </option>
                      );
                    }
                    return null;
                  })}
                </SelectStyles>
              </>
            )}
          </DivStyles>
        </SubscriptionAccess>
        {/* Rate for New and Edit */}
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 0 0 10px' minWidth='75px'>
            {strings.rate}
          </LabelStyles>
          <span>$</span>
          <InputStyles
            margin='0 10px 0 3px'
            width='70px'
            type='number'
            name='rate'
            step={props.time.rateType === 'kWh' ? '0.001' : '0.01'}
            min={props.time.rateType === 'kWh' ? '0.000' : '0.01'}
            required={props.time.rateType !== 'free'}
            placeholder={strings.placeholderRate}
            value={props.time.rate || ''}
            onChange={handleChange}
          />
          /
          <SelectStyles
            margin='0 10px'
            width='95px'
            name='rateType'
            required={props.profile.enableSchedules}
            onChange={handleChange}
            value={props.time.rateType || ''}>
            <option disabled value=''>
              {strings.placeholderType}
            </option>
            {strings.types.map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </SelectStyles>
          {(props.time.rateType === 'min' 
            || props.time.rateType === 'hr') &&
            <>
            /{' '}
              <SelectStyles
                width='160px'
                margin='0 5px 0 10px'
                onChange={handleChange}
                required
                name='rateContinueUntil'
                value={props.time.rateContinueUntil + '' || ''}>
                <option disabled value=''>
                  {strings.placeholderRateContinueUntil}
                </option>
                {strings.rateContinueUntilEnum.map((type, index) => {
                  return (
                    <option key={index} value={index}>
                      {type}
                    </option>
                  );
                })}
              </SelectStyles>
              </>
            }
        </DivStyles>
        {/* Min Amount for New and Edit */}
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 0 0 10px' minWidth='75px'>
            {strings.minAmt}
          </LabelStyles>
          <span>$</span>
          <InputStyles
            margin='0 10px 0 3px'
            width='70px'
            type='number'
            name='minAmount'
            step={'0.01'}
            min={'0.00'}
            //required={props.time.rateType !== 'free'}
            //placeholder={strings.placeholderRate}
            value={props.time.minAmount || ''}
            onChange={handleChange}
          />
        </DivStyles>
      </DivStyles>
      {/* Max Time for New and Edit */}
      <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
        <LabelStyles margin='0 10px' minWidth='75px'>
          {strings.maxTime}
        </LabelStyles>
        <SelectStyles
          margin='0 10px 0 0'
          width='95px'
          name='maxTime'
          required={props.profile.enableSchedules}
          onChange={handleChange}
          value={props.time.maxTime || 0}>
          {minsToMaxTime.map((type) => {
            return (
              <option key={type.value} value={type.value}>
                {type.display}
              </option>
            );
          })}
        </SelectStyles>
      </DivStyles>

      <SubscriptionAccess levels={[3]}>
        {/* Idle Rate for New and Edit */}
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 0 0 10px' minWidth='75px'>
            {strings.idleFee}
          </LabelStyles>
          $
          <InputStyles
            margin='0 10px 0 0'
            width='70px'
            type='number'
            name='idleRate'
            required={props.time.idleRateType !== 'none'}
            step={props.time.idleRateType === 'kWh' ? '0.001' : '0.01'}
            min={props.time.idleRateType === 'kWh' ? '0.000' : '0.01'}
            placeholder={strings.placeholderRate}
            value={props.time.idleRate || ''}
            onChange={handleChange}
          />
          /
          <SelectStyles
            margin='0 10px'
            width='95px'
            name='idleRateType'
            onChange={handleChange}
            value={props.time.idleRateType || 'none'}>
            {strings.idleTypes.map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </SelectStyles>
          <SelectStyles
            margin='0 10px'
            width='95px'
            onChange={handleChange}
            name='idleGracePeriod'
            required={props.time.idleRateType !== 'none'}
            value={props.time.idleGracePeriod || ''}>
            <option value=''>{strings.gracePlaceholder}</option>
            {minsGraceToReadable.map((time) => {
              return (
                <option key={time.value} value={time.value}>
                  {time.display}
                </option>
              );
            })}
          </SelectStyles>
          <InputStyles
              width='80px'
              margin='0 10px 0 5px'
              type='number'
              step='0.01'
              min='0.00'
              name='idleFeeMaxAmount'
              placeholder={strings.placeholderMaxAmt}
              value={props.time.idleFeeMaxAmount || ''}
              onChange={handleChange}
            />          
        </DivStyles>
      </SubscriptionAccess>
    </>
  );
};

export default Time;
